@import url(https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap);
html, body {
    height: 100%;
    overflow: hidden;
}

body {
    margin: 0;
    font-family: 'Jost', 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

#accordion {
    display: flex;
    flex: 1 1;
    overflow: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    font-size: 16px;
    font-family: 'Jost', sans-serif;
    -webkit-text-fill-color: #1C1C26;
    -webkit-box-shadow: 0 0 0 1000px white inset;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}

.react-pdf__Page__textContent span {
    opacity: 0.1;
}

.react-pdf__Page__textContent span::selection {
    background-color: blue;
}

.react-pdf__Document {
    line-height: 1;
}

.fullscreen {
    display: flex;
    flex: 1 1;
    height: 100%;
    width: 100%;
    max-width: 100%;
}

@-webkit-keyframes highlight {
    0% {
        background-color: #A60011;
        color: white;
    }
    100% {
        background-color: white;
    }
}

@keyframes highlight {
    0% {
        background-color: #A60011;
        color: white;
    }
    100% {
        background-color: white;
    }
}

.background-highlight {
    -webkit-animation: highlight 1000ms ease-out;
            animation: highlight 1000ms ease-out;
}

div html,
div html body,
div body {
    background-color: #ffffff;
    color: #000000;
    font-size: 1rem;
    font-family: open sans, Helvetica, Arial, sans-serif;
}

/* pivot table */
#fm-pivot-view .fm-ui,
#fm-pivot-view .fm-ui span,
#fm-pivot-view .fm-ui div,
#fm-pivot-view .fm-ui p,
#fm-pivot-view .fm-ui a,
#fm-pivot-view .fm-ui table,
#fm-pivot-view .fm-ui table th,
#fm-pivot-view .fm-ui table tr,
#fm-pivot-view .fm-ui table td,
#fm-pivot-view .fm-ui ul,
#fm-pivot-view .fm-ui li,
#fm-pivot-view .fm-ui input,
#fm-pivot-view .fm-ui textarea,
#fm-pivot-view .fm-ui select,
#fm-toolbar-wrapper .fm-toolbar-ui,
#fm-toolbar-wrapper .fm-toolbar-ui span,
#fm-toolbar-wrapper .fm-toolbar-ui div,
#fm-toolbar-wrapper .fm-toolbar-ui p,
#fm-toolbar-wrapper .fm-toolbar-ui a,
#fm-toolbar-wrapper .fm-toolbar-ui table,
#fm-toolbar-wrapper .fm-toolbar-ui table th,
#fm-toolbar-wrapper .fm-toolbar-ui table tr,
#fm-toolbar-wrapper .fm-toolbar-ui table td,
#fm-toolbar-wrapper .fm-toolbar-ui ul,
#fm-toolbar-wrapper .fm-toolbar-ui li,
#fm-toolbar-wrapper .fm-toolbar-ui input,
#fm-toolbar-wrapper .fm-toolbar-ui textarea,
#fm-toolbar-wrapper .fm-toolbar-ui select {
    font-family: Jost, Roboto, sans-serif;
}

#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header span.fm-ui-label {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #000;
}

#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header {
    background: inherit;
}

#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-list-header-label-wrap {
    padding: 5px;
}

#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li .fm-text-display {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    padding: 3px 8px;
}

#fm-pivot-view .fm-fields-view-wrap.fm-fields-opened {
    height: 100%;
}

#fm-pivot-view div.fm-ui-container.fm-ui-window {
    left: 0 !important;
    right: 0;
    top: 0 !important;
    bottom: 0;
    width: 100%;
    height: 100%;
}

#fm-pivot-view .fm-ui-btns-row .fm-ui-btn {
    padding: 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    border-radius: 30px;
    box-shadow: none;
    min-width: 150px;
    color: #4C4C5A;
    border: 1px solid #4C4C5A;
    text-align: center;
    text-transform: uppercase;
    height: 38px;
}

#fm-pivot-view .fm-fields-view .fm-popup-header .fm-btn-add-measure:hover:before,
#fm-pivot-view .fm-fields-view .fm-popup-header .fm-btn-add-measure:hover:focus {
    color: #FFF;
}

#fm-pivot-view .fm-ui-btns-row .fm-ui-btn:hover,
#fm-pivot-view .fm-ui-btns-row .fm-ui-btn:focus {
    font-weight: 400;
    background-color: #4C4C5A;
    color: #FFF;
}

#fm-pivot-view .fm-ui-btns-row .fm-ui-btn:active {
    font-weight: 400;
    color: #4C4C5A;
    background-color: transparent;
}

.fm-ui-element.fm-ui.fm-ui-btn:hover, .fm-ui-element.fm-ui.fm-ui-btn:focus {
    box-shadow: none;
}

#fm-pivot-view .fm-ui-btns-row .fm-ui-btn.fm-ui-btn-dark {
    background-color: #A60011;
    color: #FFF;
}

#fm-pivot-view .fm-ui-btns-row .fm-ui-btn.fm-ui-btn-dark:hover,
#fm-pivot-view .fm-ui-btns-row .fm-ui-btn.fm-ui-btn-dark:focus {
    background-color: #97000F;
}

#fm-pivot-view .fm-ui-btns-row .fm-ui-btn.fm-ui-btn-dark:active {
    background-color: #A60011;
}

.logo {
    box-sizing: border-box;
    color: rgb(113, 113, 119);
    font-family: Jost, Roboto, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 17.1667px;    
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    caret-color: black !important;
}

